import React, { useRef, useState } from 'react';
import * as THREE from 'three';

export const PointerIntersectionContext = React.createContext(null);
export const MeshContext = React.createContext(null);

function DandyMesh({
  meshRef,
  geometry,
  children,
  onPointerOver,
  onPointerOut,
}) {
  const [intersection, setIntersection] = useState(null);

  const handlePointerUpdate = (event) => {
    const {
      clientX,
      clientY,
      x,
      y,
      spaceX,
      spaceY,
      shiftKey,
      metaKey,
      ctrlKey,
      altKey,
      buttons,
    } = event;
    setIntersection({
      face: event.face,
      faceIndex: event.faceIndex,
      point: event.point,
      distance: event.distance,
      mouse: {
        clientX,
        clientY,
        x,
        y,
        spaceX,
        spaceY,
        shiftKey,
        metaKey,
        ctrlKey,
        altKey,
        buttons,
      },
    });
  };

  const handlePointerOut = (event) => {
    setIntersection(null);
    onPointerOut && onPointerOut(event);
  };

  const group = useRef();
  return (
    geometry && (
      <group ref={group}>
        <PointerIntersectionContext.Provider value={intersection}>
          <MeshContext.Provider value={{ mesh: meshRef.current }}>
            <mesh
              ref={meshRef}
              onPointerOver={onPointerOver}
              onPointerOut={handlePointerOut}
              onPointerUp={handlePointerUpdate}
              onPointerDown={handlePointerUpdate}
              onPointerMove={handlePointerUpdate}
            >
              <bufferGeometry attach="geometry" {...geometry} />
              <meshPhongMaterial
                attach="material"
                vertexColors={THREE.FaceColors}
                side={THREE.FrontSide}
              />
            </mesh>
            {children}
          </MeshContext.Provider>
        </PointerIntersectionContext.Provider>
      </group>
    )
  );
}

export default DandyMesh;
