import { useCallback, useEffect } from 'react';


function useBeforeUnload(shouldPrompt) {
  // useCallback prevents function from being redefined every time component is rendered
  const memoizedPrompt = useCallback(
    () => {
      return shouldPrompt ? true : null; // null is needed to prevent reload, not false
    },
    [shouldPrompt]
  );

  useEffect(() => {
    window.onbeforeunload = memoizedPrompt;
    return () => {
      window.onbeforeunload = null;
    }
  }, [memoizedPrompt]);
}

export default useBeforeUnload;
