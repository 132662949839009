// Increment this when format changes significantly
const VERSION = 1;

class DandyLabelExporter {
  parse(userInfo, annotationData, groupNames, object) {

    // Iterate over the valid meshes in the object
    function traverseMeshes(cb) {
      object.traverse(function (child) {
        if (child.isMesh === true) {
          const mesh = child;
          const geometry = mesh.geometry;

          if (geometry.isBufferGeometry !== true) {
            throw new Error(
              'THREE.PLYExporter: Geometry is not of type THREE.BufferGeometry.'
            );
          }

          if (geometry.hasAttribute('position') === true) {
            cb(mesh, geometry);
          }
        }
      });
    }

    const vertexMasks = {}
    groupNames.forEach((groupName) => {
      vertexMasks[groupName] = [];
    });

    traverseMeshes(function (mesh, geometry) {
      const vertices = geometry.getAttribute('position');

      groupNames.forEach((groupName) => {
        const maskAttr = geometry.getAttribute(groupName + '_mask');
        for (let i = 0, l = vertices.count; i < l; i++) {
          let val = 0;
          if (maskAttr) {
            val = maskAttr.getX(i);
          }
          vertexMasks[groupName].push(val);
        }
      });

    });

    const result = {
      ...annotationData,
      version: VERSION,
      annotatorInfo: userInfo,
      vertexMasks,
    };

    return JSON.stringify(result);
  }
}

export { DandyLabelExporter };
