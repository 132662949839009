
import { useState } from 'react';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import config from '../config.json'
import { maskVertices, restoreAllColorsByMasks } from '../Marker';

const undoLimit = config.app.undoLimit;

function useOperationStack({
    geometry,
    orderedVisibleLabels,
    annotationData,
    setAnnotationData,
}) {
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [firstDown, setFirstDown] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [baseColorAttr, setBaseColorAttr] = useState(); // saturation-adjusted color

  useKeyboardShortcuts(['KeyZ'], (code, e) => {
    if (!geometry) {
        return;
    }
    if (!e.ctrlKey && !e.metaKey) {
      return;
    }
    if (!e.shiftKey) {
      if (undoStack.length > 0) {
        console.log('pop from undo stack');
        // add to redo stack
        const redo = {
          groupMasks: config.groups.map((groupId) => geometry.getAttribute(`${groupId}_mask`).clone()),
          annotationData,
        };
        setRedoStack([
          ...redoStack,
          redo,
        ]);

        // now handle undo
        const undo = undoStack.slice(-1)[0];
        setUndoStack(undoStack.slice(0, -1));
        config.groups.forEach((groupId, groupIdx) => {
          geometry.setAttribute(`${groupId}_mask`, undo.groupMasks[groupIdx]);
        });
        maskVertices.reset(geometry);
        restoreAllColorsByMasks(geometry, orderedVisibleLabels, baseColorAttr);
        setAnnotationData(undo.annotationData);
        e.preventDefault(); // prevent from bubbling
      }
    } else {
      if (redoStack.length > 0) {
        console.log('pop from redo stack');
        // add to undo stack
        const undo = {
          groupMasks: config.groups.map((groupId) => geometry.getAttribute(`${groupId}_mask`).clone()),
          annotationData,
        };
        setUndoStack([
          ...undoStack,
          undo,
        ]);

        // now handle redo
        const redo = redoStack.slice(-1)[0];
        setRedoStack(redoStack.slice(0, -1));
        config.groups.forEach((groupId, groupIdx) => {
          geometry.setAttribute(`${groupId}_mask`, redo.groupMasks[groupIdx]);
        });
        maskVertices.reset(geometry);
        restoreAllColorsByMasks(geometry, orderedVisibleLabels, baseColorAttr);
        setAnnotationData(redo.annotationData);
        e.preventDefault(); // prevent from bubbling
      }
    }
  }, 'keydown');

  const saveSnapshot = () => {
    const undo = {
      groupMasks: config.groups.map((groupId) => geometry.getAttribute(`${groupId}_mask`).clone()),
      annotationData,
    };
    setUndoStack([
      ...(undoLimit === 1 ? [] : undoLimit ? undoStack.slice(-undoLimit + 1) : undoStack),
      undo,
    ])
    setRedoStack([]);
    setIsDirty(true);
    console.log('push to undo stack');
  };

  return {saveSnapshot, firstDown, setFirstDown, isDirty, setIsDirty, baseColorAttr, setBaseColorAttr};
}

export default useOperationStack;
