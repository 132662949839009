export class VertexFaceIndex {
  constructor(geometry) {
    this.geometry = geometry;
    this.positions = this.geometry.getAttribute('position');
    this.vertexIndex = this.geometry.index;
    this.data = {};
  }

  build() {
    for (let faceIndex = 0; faceIndex < this.vertexIndex.count / 3; faceIndex++) {
      const face = this.readFaceByFaceIndex(faceIndex);

      this.addVertexFacePair(face.a, faceIndex);
      this.addVertexFacePair(face.b, faceIndex);
      this.addVertexFacePair(face.c, faceIndex);
    }
  }

  getVertexIndex(vi) {
    return this.vertexIndex.getX(vi);
  }

  readFaceByFaceIndex(faceIndex) {
    let vertexIndex = this.getVertexIndex(faceIndex * 3);
    let vertexIndex1 = this.getVertexIndex(faceIndex * 3 + 1);
    let vertexIndex2 = this.getVertexIndex(faceIndex * 3 + 2);

    return {
      a: this.getVertexPosition(vertexIndex),
      b: this.getVertexPosition(vertexIndex1),
      c: this.getVertexPosition(vertexIndex2),
    };
  }

  addVertexFacePair(v, faceIndex) {
    const vh = getVertexHash(v);
    if (!this.data[vh]) {
      this.data[vh] = [];
    }

    this.data[vh].push(faceIndex);
  }

  getFacesByVertexIndex(vi) {
    const v = this.getVertexPosition(vi);

    return this.data[getVertexHash(v)] || [];
  }

  getVertexPosition(vi) {
    return [
      this.positions.getX(vi),
      this.positions.getY(vi),
      this.positions.getZ(vi),
    ];
  }
}
const precision = Math.pow(10, 3);
function getVertexHash(v) {
  return `${Math.ceil(v[0] * precision)}_${Math.ceil(
    v[1] * precision
  )}_${Math.ceil(v[2] * precision)}`;
}
