import React, { useState } from 'react';

import styles from './styles.module.css';
import TextCheckbox from './text-checkbox.js';

import options from '../config.json';

import useKeyboardShortcuts from '../useKeyboardShortcuts';
import ShortcutIcon from '../ShortcutIcon';
import { maskVertices, restoreColorByMasks } from '../Marker';

const keyCodes = Array(9)
  .fill(0)
  .map((_, i) => `Digit${i + 2}`)
  .slice(0, Math.min(8, options.labels.length));

const attributesById = {};
options.attributes.forEach((attribute) => {
  attributesById[attribute.id] = attribute;
})

function LabelSelect({
  label,
  onChange,
  visibleLabels,
  setVisibleLabels,
  hideAllLabels,
  setHideAllLabels,
  isFilterFocused,
  setIsFilterFocused,
  annotationData,
  setAnnotationData,
  geometry,
  saveSnapshot,
}) {
  // filter labels to those in the specific jaw
  const jaw = annotationData?.jaw;
  const labels = options.labels.filter((l) => {
    if (l.group === 'Tooth') {
      if (jaw === 'upper' && l.id > 16 || jaw === 'lower' && l.id <= 16) {
        return false;
      }
    }
    return true;
  });

  // For dealing with filtering
  const [filterText, setFilterText] = useState("");

  const onFilterTextChange = (e) => {
    const newInput = e.currentTarget.value;
    setFilterText(newInput);
  }

  const filterTextPredicate = (value) => {
    if (!filterText) {
      return true;
    }
    return value.label.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
  }

  const shortcutPrefix = isFilterFocused ? "^" : "";

  function handleSelect(label) {
    if (label && !visibleLabels.includes(label)) {
      const newVal = visibleLabels.slice();
      newVal.push(label);
      setVisibleLabels(newVal);
    }
    onChange && onChange(label);
  }

  function handleAttributeChange(groupId, labelId, attributeId, attributeValue) {
    console.log('handleAttributeChange', groupId, labelId, attributeId, attributeValue);
    const attributes = annotationData?.attributes || {};
    saveSnapshot();
    setAnnotationData({
      ...annotationData,
      attributes: {
        ...attributes,
        [`${groupId}-${labelId}`]: {
          ...attributes?.[labelId],
          [attributeId]: attributeValue,
        },
      },
    });
  }

  function handleLabelMove(groupId, sourceLabelId, targetLabelId) {
    console.log('handleLabelMove', groupId, sourceLabelId, targetLabelId);
    const attrName = `${groupId}_mask`;
    const maskAttr = geometry.getAttribute(attrName);
    if (!maskAttr) {
      return;
    }
    saveSnapshot();
    const verticesToUpdate = [];
    if (targetLabelId === '*Prep Copy*') {
      const prepMaskAttr = geometry.getAttribute('PrepTooth_mask');
      if (!prepMaskAttr) {
        return;
      }
      for (let vertexId = 0; vertexId < prepMaskAttr.count; vertexId++) {
        if (maskAttr.getX(vertexId) == sourceLabelId) {
          prepMaskAttr.setX(vertexId, 1);
          verticesToUpdate.push(vertexId);
        }
      }
    } else {
      const setLabelId = targetLabelId === '*Trash*' ? 0 : targetLabelId;
      for (let vertexId = 0; vertexId < maskAttr.count; vertexId++) {
        if (maskAttr.getX(vertexId) == sourceLabelId) {
          maskAttr.setX(vertexId, setLabelId);
          verticesToUpdate.push(vertexId);
        }
      }
    }
    maskVertices.reset(geometry);
    const baseColorAttr = geometry.getAttribute('original_color');
    restoreColorByMasks(verticesToUpdate, geometry, visibleLabels, baseColorAttr);
  }

  useKeyboardShortcuts(['Digit1', ...keyCodes], (code) => {
    if (code === 'Digit1') {
      handleSelect(null);
    } else {
      handleSelect(labels.filter(filterTextPredicate)[keyCodes.indexOf(code)]);
    }
  });

  function toggleArrayElement(l, array, setFunction, e) {
    const _array = array.slice();
    const i = _array.indexOf(l);
    if (i >= 0) {
      _array.splice(i, 1);
    } else {
      _array.push(l);
    }
    setFunction(_array);
  }

  return (
    <div className={styles.dropdownList}>
      <div
        className={`option ${label ? '' : styles.selected}`}
        onClick={() => {
          handleSelect(null);
        }}
      >
        <ShortcutIcon character={`${shortcutPrefix}1`} />
        <span className={styles.label}>Move tool</span>
      </div>

      <div className={styles.labelsHeader}>
        <span>
          <input
            placeholder="Filter label classes"
            text={filterText}
            onChange={onFilterTextChange}
            onFocus={() => setIsFilterFocused(true)}
            onBlur={() => setIsFilterFocused(false)}
          />
        </span>
        <span className={styles.spacer}></span>
        <span>
          <TextCheckbox
            text={visibleLabels.length > 0 ? 'Hide all' : 'Show all'}
            checked={true}
            onChange={() => {
              setVisibleLabels(visibleLabels.length > 0 ? [] : options.labels);
            }}
          ></TextCheckbox>
        </span>
      </div>

      {labels.filter(filterTextPredicate).map((l, index) => (
        <div
          key={l.label}
          className={`option ${l === label ? styles.selected : ''}`}
        >
          <span
            key="selector"
            className={styles.selector}
            onClick={() => {
              handleSelect(l);
            }}
          >
            {index <= 7 && <ShortcutIcon character={`${shortcutPrefix}${index + 2}`} />}
            <span
              key="color"
              className={styles.colorSquare}
              style={{ backgroundColor: l.color }}
            />
            <span key="spacer" className={styles.spacer}></span>
            <span key="label" className={styles.label}>{l.label}</span>
          </span>
          <span key="spacer" className={styles.spacer}></span>
          <span key="attributes" className={styles.attributes}>
            {l.attributes?.map((attributeId, attributeIdx) => {
              // selectedValue will be first item if nothing is selected, so that undo will properly reset to default
              const selectedValue = (annotationData?.attributes?.[`${l.group}-${l.id}`] ?? {})[attributeId] ?? attributesById[attributeId].values[0];
              return <span key={attributeIdx}>
                {attributesById[attributeId].label}:
                <select
                  name={`${l.group}-${l.id}-${attributeId}`}
                  onChange={(e) => {
                    handleAttributeChange(l.group, l.id, attributeId, e.currentTarget.value);
                  }}
                  value={selectedValue}
                >
                  {attributesById[attributeId].values.map((attributeVal, attributeValIdx) => {
                    return <option key={attributeValIdx} value={attributeVal}>{attributeVal}</option>;
                  })}
                </select>
              </span>;
            })}
          </span>
          <span key="move" className={styles.moves}>
            Move to:
            <select
              name={`${l.group}-${l.id}-move`}
              onChange={(e) => {
                handleLabelMove(l.group, l.id, e.currentTarget.value);
              }}
              value={l.id}
            >
              {labels
              .filter((moveToLabel) => l.group === moveToLabel.group)
              .map((moveToLabel, moveToIndex) => {
                return <option key={moveToIndex} value={moveToLabel.id}>
                  {(l.id === moveToLabel.id) ? '-----' : moveToLabel.label}
                </option>;
              })}
              <option key={-1} value={'*Trash*'}>*Trash*</option>
              {
                l.group === 'Tooth' &&
                <option key={-2} value={'*Prep Copy*'}>*Prep Copy*</option>
              }
            </select>
          </span>
          <span>
            <TextCheckbox
              text={'Show'}
              checked={visibleLabels.includes(l)}
              onChange={toggleArrayElement.bind(
                undefined,
                l,
                visibleLabels,
                setVisibleLabels
              )}
            ></TextCheckbox>
          </span>
        </div>
      ))}
    </div>
  );
}

export default LabelSelect;
